@import "amdocs-core-package/lib/assets/scss/variables.scss";
@import "amdocs-core-package/lib/assets/scss/mixin.scss";
@import "_colors";
@import "fonts";
@import "button";
@import "mixin";
@import "popup";
@import "toastr";

//$windowHeight: calc(var(--vh, 1vh) * 100);
$iosAddToHomeIndex: 140;
$fullPageLoaderIndex: 2000;
$userAlertIndex: 2000;

$mobile-width: 600px;
