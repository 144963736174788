.custom-input-label {
  @include font-base;
  margin-bottom: 4px;
}

.custom-input-field-control {
  height: 32px;
  padding: 8px;
  border: 1px solid $fontColor;
  border-radius: 6px;

  .selected-value {
    @include font-base;
  }

  &::placeholder {
    @include font-base;
  }

  &:disabled {
    background-color: $colorP5;
    color: #ABACBA;
    border-color: transparent;
  }
}


//.autocomplete-wrapper {
//
//  .autocomplete-label {
//    margin-bottom: 4px !important;
//
//    div {
//      @include font-base;
//    }
//  }
//
//  .ng-select {
//    // TODO:: check different fix
//    .ng-select-container {
//      height: 32px !important;
//      padding: 8px !important;
//      border: 1px solid $fontColor !important;
//      border-radius: 6px !important;
//
//      .ng-value-container {
//
//        .ng-placeholder {
//          @include font-base;
//        }
//
//        .ng-input > input {
//          @include font-base;
//        }
//      }
//    }
//    .ng-arrow-wrapper:before {
//      background-image: url('/aseets/icons/arrow-down-new.svg');
//    }
//  }
//
//}




