@import "amdocs-core-package/lib/assets/scss/core";
@import "amdocs-core-package/lib/assets/scss/scroll";
@import "amdocs-core-package/lib/assets/scss/datepicker";
@import "../../../node_modules/ngx-toastr/toastr";
@import "shared-variables";
@import "~@ng-select/ng-select/themes/default.theme.css";

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

.toast-container {
  left: 0;
  right: 0;
  position: fixed;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  background-color: #f8f8f8;
}

div {
  font-weight: $weightMedium;
}

.pointer {
  cursor: pointer;
}

.btn:not(.capitalize) {
  text-transform: initial;
}

.mimic-wrapper {
  z-index: 10;

  input {
    width: 60px;
  }
}

.lds-roller {
  z-index: $fullPageLoaderIndex;
}

.d-flex {
  display: flex;
}

.btn.dark {
  color: #fff!important;
}

.amdocs-user-notify-alert-wrapper {
  z-index: $userAlertIndex;
}

.ProgressiveWebApp-notification {
  z-index: $iosAddToHomeIndex;
}

.white-link {
  color: $white;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $white;
  }
}

body.show-splash {
  background-color: $colorP3;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.modal {
  z-index: 1062;

  .modal-dialog {
    margin: 90px auto auto auto;
    max-width: 900px;
  }

  .modal-header {
    padding: 21px 24px 24px;
  }

  .modal-content {
    padding: 0;
    width: 900px;
  }
}

.pointer {
  cursor: pointer;
}

.full-height {
  height: 100%;
}

.card {
  box-shadow: 0px 2px 8px rgba(19, 19, 24, 0.16);
  border-radius: 8px;
}

.page-content-container {
  margin-top: 44px;
}

.page-form-wrapper {
  background-color: $white;
  box-shadow: 0px 2px 8px rgba(19, 19, 24, 0.16);
  border-radius: 8px;
  margin-bottom: 58px;

  .accordion-header {
    height: 64px;
    border-bottom: 1px solid $colorD5;
    padding: 20px 24px;
  }

  .app-learning-plan-form {
    display: block;
    padding: 24px;
  }
}

@media (max-width: $mobile-width) {
  .modal-dialog {
    margin: 15px 0 0;
  }

  .modal-content {
    &.accept-reject-popup {
      width: 100%;
      min-width: 350px;

      .desktop-popup-wrapper {
        .header-wrapper {
          text-align: left;
          padding: 20px 24px !important;
        }

        .content-wrapper {
          padding: 24px !important;
        }
      }
    }
  }

  body.show-splash {
  }
}
