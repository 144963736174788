.underline-link {
  cursor: pointer;
  text-decoration: underline;
}

.buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.btn-outline {
  border: 1px solid $fontColor;
  border-color: $fontColor;
  padding: 10px 14px;
  color: $fontColor;
  font-weight: 500;
  background-color: $white;

  &.disabled {
    pointer-events: none;
    opacity: .45;
    background-color: transparent;
  }

  &:hover,
  &:focus {
    background-color: $colorD7;
  }
}

.btn-link {
  padding: 10px 14px;
  color: $fontColor;
  font-weight: 500;

  &.disabled {
    pointer-events: none;
    opacity: .45;
    background-color: transparent;
  }

  &:hover,
  &:focus {
    background-color: $mhLighterGray;
  }
}
