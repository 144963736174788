.modal {
  .modal-content {
    .mh-popup-header {
      text-align: left;
      border-bottom: 1px solid $mhLightGray;
      padding: 24px 0 24px 30px;
    }

    &.create-learning-item {
      width: 760px;
      min-height: 702px;

      .mh-popup-header {
        padding: 21px 0 24px 24px;
      }
    }

    &.open-plan {
      width: 1030px;
    }
  }

  .modal-dialog {
    &.popup-960 {
      max-width: 960px;

      .modal-content {
        width: 960px;
      }
    }

    &.preview-modal {
      max-width: 90%;

      .modal-content {
        width: 100%;
      }
    }
  }
}
