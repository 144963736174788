
// ::ng-deep {
  .toast-container {
    left: 0;
    right: 0;
    position: fixed;

    .ngx-toastr {
      border-radius: 8px;
      border-top: 4px solid;
      padding: 20px 24px 20px 60px;
      background-position: 24px center;
      width: 100%;
      //display: flex;
      //align-items: center;
      //justify-content: space-between;
      //flex-direction: row-reverse;

      .toast-close-button{
        position: absolute;
        right: 24px;
        top: 10px;

        span{
          font-size: 20px;
          color: #666d83;
          font-weight: 500;
          line-height: normal;
        }
      }

        // TODO general css - ZION
      .toast-title {
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
      }

      .toast-message {
        margin-top: 7px;
        font-size: 12px;
        line-height: 18px;
      }

      &.toast-info {
        background-color: #DDDEFC;
        border-color: #424484;
        // TODO: change icon
        //background-image: url("/assets/icons/toaster-info.svg") !important;
      }

      &.toast-success {
        background-color: #CDF4E3;
        border-color: #05BF6F;
        // TODO: change icon
        background-image: url("/assets/icons/toaster-success.svg") !important;
      }

      &.toast-error {
        border-color: #DF1744;
        background-color: #FFE5EB;
        // TODO: change icon
        //background-image: url("/assets/icons/toaster-error.svg") !important;
      }

      &.toast-warning {
        border-color: #EBA606;
        background-color: #FFF2D5;
        // TODO: change icon
        //background-image: url("/assets/icons/toaster-warning.svg") !important;

      }
    }
  }
// }
